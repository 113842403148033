module.exports={
  "//": "This is an automatic translation. Help us to improve it.",
  "loadingTitle": "Aguarde...",
  "close": "Fechar",
  "signin": {
    "title": "Entrar",
    "action": "Entrar",
    "all": "Mostrar todos",
    "strategyEmailEmpty": "O e-mail é vazio.",
    "strategyEmailInvalid": "O email é inválido.",
    "strategyDomainInvalid": "O domínio {domain} não foi a instalação.",
    "signupText": "Inscrição",
    "forgotText": "Esqueceu sua senha?",
    "emailPlaceholder": "Email",
    "usernamePlaceholder": "Nome de Usuário",
    "passwordPlaceholder": "Senha",
    "separatorText": "ou",
    "serverErrorText": "Houve um erro ao processar o sinal em.",
    "returnUserLabel": "Da última vez que você assinou usando...",
    "wrongEmailPasswordErrorText": "Senha ou e-mail errado.",
    "or": "... ou Cadastre-se no uso de",
    "loadingMessage": "Entrar com {connection}..."
  },
  "signup": {
    "description": "",
    "title": "Inscrição",
    "action": "Inscrição",
    "emailPlaceholder": "Email",
    "passwordPlaceholder": "Crie uma senha",
    "cancelAction": "Cancelar",
    "headerText": "Por favor, digite seu e-mail e senha",
    "footerText": "",
    "signupOnSSODomainErrorText": "Este domínio {domain} tiver sido configurado para Sign-On único e você não pode criar uma conta. Tente entrar em vez disso.",
    "serverErrorText": "Houve um erro ao processar o sinal de cima."
  },
  "reset": {
    "title": "Redefinir senha",
    "action": "Enviar",
    "emailPlaceholder": "Email",
    "passwordPlaceholder": "Nova senha",
    "repeatPasswordPlaceholder": "Confirmar nova senha",
    "cancelAction": "Cancelar",
    "successText": "Só te enviamos um e-mail para redefinir sua senha.",
    "enterSamePasswordText": "Por favor digite a mesma senha.",
    "headerText": "Por favor, digite seu e-mail e a senha nova. Nós lhe enviaremos um e-mail para confirmar a alteração de senha.",
    "serverErrorText": "Houve um erro ao processar a redefinir a senha."
  }
}
