module.exports={
  "loadingTitle":                  "Vennligst vent...",
  "close":                         "Lukk",
  "windowsAuthTitle":              "Windows-autentisering",
  "invalid":                       "Ugyldig",
  "mustMatch":                     "Må tilsvare",
  "loginSocialButton":             "Logg inn med {connection:title}",
  "signupSocialButton":            "Registrer med {connection:title}",
  "signin": {
    "title":                       "Innlogging",
    "action":                      "Logg inn",
    "actionDomain":                "Logg inn {domain}",
    "all":                         "Vis alle",
    "strategyEmailEmpty":          "E-posten er ikke utfylt.",
    "strategyEmailInvalid":        "E-posten er ugyldig.",
    "strategyDomainInvalid":       "Domenet {domain} er ikke konfigurert.",
    "signupText":                  "Registrer",
    "forgotText":                  "Tilbakestill passord",
    "cancelAction":                "Avbryt",
    "emailPlaceholder":            "E-post",
    "usernamePlaceholder":         "Brukernavn",
    "passwordPlaceholder":         "Passord",
    "separatorText":               "eller",
    "serverErrorText":             "Det oppstod en feil under innlogging",
    "returnUserLabel":             "Sist gang logget du inn med...",
    "domainUserLabel":             "Du er tilkoblet ditt nettverk...",
    "wrongEmailPasswordErrorText": "Feil e-post eller passord.",
    "or":                          "... eller logg inn med",
    "loadingMessage":              "Logger inn med {connection}...",
    "popupCredentials":            "Skriv inn identifikasjon i pop-up-vinduet",
    "userClosedPopup":             "Pop-up-vindu lukket. Prøv igjen.",
    "userConsentFailed":           "Nødvendige tilganger ble ikke gitt. Vennligst prøv igjen."
  },
  "signup": {
    "description":                 "",
    "title":                       "Registrer",
    "action":                      "Registrer",
    "emailPlaceholder":            "E-post",
    "passwordPlaceholder":         "Opprett passord",
    "cancelAction":                "Avbryt",
    "headerText":                  "Vennligst skriv inn e-post og passord",
    "footerText":                  "",
    "signupOnSSODomainErrorText":  "Domenet {domain} er konfigurert med Single Sign On og du kan ikke opprette en konto selv. Forsøk å logge inn istedet.",
    "serverErrorText":             "Det oppstod en feil under innlogging.",
    "userExistsErrorText":         "Brukeren finnes allerede.",
    "invalidPassword":             "Passordet er for svakt."
  },
  "reset": {
    "title":                       "Tilbakestill passord",
    "action":                      "Send",
    "emailPlaceholder":            "E-post",
    "passwordPlaceholder":         "Nytt passord",
    "repeatPasswordPlaceholder":   "Bekreft nytt passord",
    "cancelAction":                "Avbryt",
    "successText":                 "Du har blitt tilsendt en e-post for å tilbakestille passordet ditt.",
    "enterSamePasswordText":       "Vennligst skriv inn det samme passordet.",
    "headerText":                  "Vennligst skriv inn e-post og ditt nye passord. Vi sender deg en e-post for å bekrefte endringen.",
    "serverErrorText":             "Det oppstod en feil under tilbakestilling av passordet.",
    "userDoesNotExistErrorText":   "Brukeren finnes ikke."
  }
}
