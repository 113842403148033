module.exports = (function anonymous(locals, filters, escape, rethrow
/**/) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div class="a0-create-account a0-buttons-actions">\n  ');2; if (locals.showCancel) { ; buf.push('\n  <a href="', escape((3,  locals.cancelLink || 'javascript: {}' )), '"\n    class="a0-cancel  a0-btn-small">', escape((4,  i18n.t('signin:cancelAction') )), '</a>\n  ');5; } ; buf.push('\n  ');6; if (showSignupAction) { ; buf.push('\n  <a href="', escape((7,  locals.signupLink || 'javascript: {}' )), '"\n    class="a0-sign-up a0-btn-small">\n    ', escape((9,  i18n.t('signin:signupText') )), '\n  </a>\n  ');11; } ; buf.push('\n  ');12; if (showSignupAction && showResetAction) { ; buf.push('\n  <span class="a0-divider"></span>\n  ');14; } ; buf.push('\n  ');15; if (showResetAction) { ; buf.push('\n  <a href="', escape((16,  locals.resetLink || 'javascript: {}' )), '"\n    class="a0-forgot-pass  a0-btn-small">', escape((17,  i18n.t('signin:forgotText') )), '</a>\n  ');18; } ; buf.push('\n</div>\n'); })();
} 
return buf.join('');
})