module.exports = (function anonymous(locals, filters, escape, rethrow
/**/) {
escape = escape || function (html){
  return String(html)
    .replace(/&(?!#?[a-zA-Z0-9]+;)/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&quot;');
};
var buf = [];
with (locals || {}) { (function(){ 
 buf.push('<div class="a0-loggedin a0-mode">\n    <form novalidate>\n        <span class="a0-centered a0-last-time">', escape((3,  i18n.t('signin:returnUserLabel') )), '</span>\n        <div class="a0-strategy"></div>\n        <div class="a0-emailPassword a0-hide">\n            <div class="a0-email">\n                <span class="a0-email-readonly"></span>\n                <input name="email"\n                       type="email"\n                       value=""\n                       disabled\n                       placeholder="', escape((12,  i18n.t('signin:emailPlaceholder') )), '"\n                       title="', escape((13,  i18n.t('signin:emailPlaceholder') )), '"\n                       class="a0-hide">\n            </div>\n            <div class="a0-password">\n                <label class="a0-sad-placeholder">\n                  ', escape((18,  i18n.t('signin:passwordPlaceholder') )), '\n                </label>\n                <input name="password" type="password"\n                       autofocus\n                       placeholder="', escape((22,  i18n.t('signin:passwordPlaceholder') )), '"\n                       title="', escape((23,  i18n.t('signin:passwordPlaceholder') )), '">\n            </div>\n            <div class="a0-action">\n                <button type="submit" class="a0-primary a0-next" style="width: 100%;">', escape((26,  i18n.t('signin:action') )), '</button>\n                ');27; if (options.showForgot !== false) { ; buf.push('\n                <label class="a0-create-account">\n                  <a href="', escape((29,  options.resetLink || 'javascript: {}' )), '"\n                    class="a0-forgot-pass">', escape((30,  i18n.t('signin:forgotText') )), '</a>\n                </label>\n                ');32; } ; buf.push('\n            </div>\n        </div>\n        <a href="#" class="a0-centered a0-all a0-btn-small">', escape((35,  i18n.t('signin:all') )), '</a>\n    </form>\n</div>\n'); })();
} 
return buf.join('');
})