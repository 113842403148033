module.exports={
  "loadingTitle":                  "Aguarde...",
  "close":                         "Fechar",
  "windowsAuthTitle":              "Autenticação do Windows",
  "invalid":                       "Inválido",
  "mustMatch":                     "Deve corresponder",
  "loginSocialButton":             "Entre com {connection:title}",
  "signupSocialButton":            "Cadastre com {connection:title}",
  "networkError":                  "Não conseguimos acessar o servidor.<br/>Por favor, tente novamente.",
  "signin": {
    "title":                       "Entrar",
    "action":                      "Entrar",
    "actionDomain":                "Entrar no domínio {domain}",
    "all":                         "Mostrar todos",
    "strategyEmailEmpty":          "O e-mail não foi informado.",
    "strategyEmailInvalid":        "O e-mail está incorreto.",
    "strategyDomainInvalid":       "O domínio {domain} não está configurado.",
    "signupText":                  "Cadastre-se",
    "forgotText":                  "Esqueceu sua senha?",
    "cancelAction":                "Cancelar",
    "emailPlaceholder":            "E-mail",
    "usernamePlaceholder":         "Nome de usuário",
    "passwordPlaceholder":         "Senha",
    "separatorText":               "ou",
    "serverErrorText":             "Houve um erro durante o login.",
    "returnUserLabel":             "Da última vez você entrou usando...",
    "domainUserLabel":             "Você está conectado da sua rede corporativa...",
    "wrongEmailPasswordErrorText": "E-mail ou senha inválidos.",
    "or":                          "... ou entre usando",
    "loadingMessage":              "Entrar com {connection}...",
    "popupCredentials":            "Informe seus dados de acesso na janela pop-up",
    "userClosedPopup":             "A janela pop-up está fechada. Por favor, tente novamente.",
    "userConsentFailed":           "As permissões não foram concedidas. Por favor, tente novamente."
  },
  "signup": {
    "description":                 "",
    "title":                       "Cadastre-se",
    "action":                      "Cadastre-se",
    "emailPlaceholder":            "E-mail",
    "usernamePlaceholder":         "Crie um nome de usuário",
    "passwordPlaceholder":         "Crie uma senha",
    "cancelAction":                "Cancelar",
    "headerText":                  "Por favor, preencha seu e-mail e senha",
    "footerText":                  "",
    "signupOnSSODomainErrorText":  "O domínio {domain} está configurado para Single Sign On e você não pode criar uma conta. Entre usando uma conta.",
    "serverErrorText":             "Ocorreu um erro durante o cadastro.",
    "userExistsErrorText":         "O usuário já existe.",
    "usernameInUseErrorText":      "Este nome de usuário já está em uso.",
    "invalidPassword":             "A senha não está segura o suficiente.",
    
    "passwordStrength": {
      "nonEmpty": "A senha deve ser preenchida",
      "lengthAtLeast": "Deve ter pelo menos %d de comprimento",
      "shouldContain": "Deve conter:",
      "containsAtLeast" : "Contém pelo menos %d dos seguintes %d tipos de caracteres:",
      "lowerCase": "Letras minúsculas (a-z)",
      "upperCase": "Letras maiúsculas (A-Z)",
      "numbers": "Números (0-9)",
      "specialCharacters" : "Símbolos (!@#$%^&*)",
      "identicalChars": "Máximo de %d caracteres iguais seguidos (ex. \"%s\" não é permitido)"
    }
    
  },
  "reset": {
    "title":                       "Redefinir senha",
    "action":                      "Enviar",
    "emailPlaceholder":            "E-mail",
    "passwordPlaceholder":         "Nova senha",
    "repeatPasswordPlaceholder":   "Confirmar nova senha",
    "cancelAction":                "Cancelar",
    "successText":                 "Te enviamos um e-mail para redefinir sua senha.",
    "enterSamePasswordText":       "Por favor, use a mesma senha.",
    "headerText":                  "Por favor, informe o e-mail e a nova senha. Nós enviaremos um e-mail para você confirmar a alteração de senha.",
    "serverErrorText":             "Ocorreu um erro durante a redefinição de senha.",
    "userDoesNotExistErrorText":   "Usuário não existe.",
    "invalidPassword":             "A senha não está segura o suficiente."
  }
}
